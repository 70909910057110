import { Link } from "gatsby";
import React from "react";

const FeaturdPostsSidebarComponent = () => {
    let featuredPosts = [
        {
            "title": "Migrating from Synology DS415Play to DS1821+",
            "summary": "I discuss how I moved data from my Synology DS415Play to a DS1821+ and overcoming the limited migration options available due to the age of my current NAS.",
            "url": "/Blog/2023/06/17/Migrating-From-Synology-DS415Play-To-DS1821-Plus",
            "image": "https://ik.imagekit.io/surinderbhomra/Blog/Stock/GuillaumeBolducShippingContainers.jpg?tr=w-700"
        },
        {
            "title": "SimpleLogin.io - An Email Service I Never Thought I Needed... Until Now",
            "summary": "SimpleLogin's email alias service has completely transformed how I create new email addresses while also providing additional privacy and security features.",
            "url": "/Blog/2022/11/24/SimpleLogin-io-An-Email-Service-Never-Thought-I-Needed",
            "image": "https://ik.imagekit.io/surinderbhomra/Blog/Stock/BenoitDebaixMailboxesCoast.jpg?tr=w-700"
        },
        {
            "title": "Stack Overflow Trigger-Happy Downvoting",
            "summary": "Stack Overflow is one of the best resources to get answers to any tech question. I'm trying to make sense of 'trigger-happy' downvoting that goes on at times without any clear context as to why?",
            "url": "/Blog/2023/01/30/Stack-Overflow-Trigger-Happy-Downvoting",
            "image": "https://ik.imagekit.io/surinderbhomra/Blog/Random%20Thoughts/StackOverflowBanner.png?tr=w-700"
        },
    ]

    return (
        <div className="py-4">
            <h2 className="text-sm tracking-wide text-gray-500 dark:text-gray-400 mb-1 font-medium">
                Featured Posts
            </h2>
            {featuredPosts.map((node) => (
                <div key={node.url} className="pb-6 grid grid-cols">
                    <Link to={node.url} className="overflow-hidden shadow-lg">
                        <img className="w-full" src={node.image} alt={node.title} loading="lazy" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">{node.title}</div>
                            <p className="text-gray-700 text-base">
                                {node.summary}
                            </p>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    )
}

export default FeaturdPostsSidebarComponent